<template>
  <div>
    <b-card
      title="Embellishments"
    >
      <b-card-text>
        <b-table
          ref="refEmbellishmentListTable"
          class="position-relative"
          :items="fetchStyleEmbellishments"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="Without Embellishments"
        >

          <!-- Column: Created at -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              {{ data.item.created_at }}
            </div>
          </template>

          <!-- Column: Category type -->
          <template #cell(category_type)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                {{ data.item.category_type }}
              </div>
              <div>
                <b-avatar
                  v-for="media in imageMedia(data.item.style_embellishment_media)"
                  :key="media.id"
                  :src="media.image"
                  :style="{width: '3em', height: '3em'}"
                  :title="media.name"
                  square
                  class="mr-1"
                />
              </div>
            </div>
          </template>

          <!-- Column: Ref -->
          <template #cell(description)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="align-text-top">
                  {{ data.item.specifics_description }}
                </div>
                <div class="align-text-top">
                  {{ data.item.dimensions_description }}
                </div>
              </div>
              <feather-icon
                v-if="data.item.placement"
                v-b-tooltip.hover
                icon="TargetIcon"
                class="d-inline mr-1"
                :title="data.item.placement"
              />
              <feather-icon
                v-if="data.item.feed && data.item.feed.length != 0"
                icon="MessageCircleIcon"
                class="d-inline mr-1"
              />
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-card>
    <b-card
      v-if="feed.length > 0"
    >
      <b-card-text>
        <feed
          ref="refFeed"
          :feed="feed"
          :feed-config="feedConfig"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BTable,
} from 'bootstrap-vue'
import {
  computed,
} from '@vue/composition-api'

import useStyleEmbellishmentsList from '../styles-edit/useStyleEmbellishmentsList'
import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BTable,

    Feed,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      fetchStyleEmbellishments,
      refEmbellishmentListTable,
      sortBy,
      isSortDirDesc,
    } = useStyleEmbellishmentsList()

    const tableColumns = [
      { key: 'category_type' },
      { key: 'description' },
      { key: 'id', sortable: true, label: 'Created at' },
    ]
    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }

    const feed = computed(() => {
      const allFeeds = []
      if (refEmbellishmentListTable.value) {
        refEmbellishmentListTable.value.localItems.forEach(embellishment => {
          embellishment.feed.forEach(embellishmentFeed => {
            allFeeds.push(embellishmentFeed)
          })
        })
      }
      return allFeeds.sort((a, b) => a.id - b.id)
    })

    const imageMedia = allMedia => allMedia.filter(media => media.image)

    return {
      fetchStyleEmbellishments,

      tableColumns,
      sortBy,
      isSortDirDesc,
      refEmbellishmentListTable,
      imageMedia,
      feed,
      feedConfig,
    }
  },
}
</script>

<style>

</style>
