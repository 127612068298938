<template>
  <div>
    <b-card
      title="Sample Requests"
    >
      <b-card-text>
        <b-container>
          <b-row
            class="px-2 py-4"
          >
            <b-col
              v-for="sample in rawSampleRequests"
              :key="sample.id"
              class="sample-card-box"
            >
              <sample-request-card
                :sample="sample"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import SampleRequestCard from '../../sample-request/sample-requests-list/SampleRequestCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BContainer,
    BRow,

    SampleRequestCard,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    rawSampleRequests: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="css">
.sample-card-box {
  flex: 260px 0;
}
</style>
