<template>
  <div>
    <b-card
      title="Materials"
    >
      <b-card-text>
        <b-table
          ref="refMaterialListTable"
          class="position-relative"
          :items="fetchStyleMaterials"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="Without Materials"
        >

          <!-- Column: Created at -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              {{ data.item.created_at }}
            </div>
          </template>

          <!-- Column: Category type -->
          <template #cell(category_type)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                {{ data.item.category_type }}
              </div>
              <div>
                <b-avatar
                  v-for="media in imageMedia(data.item.style_material_media)"
                  :key="media.id"
                  :src="media.image"
                  :style="{width: '3em', height: '3em'}"
                  :title="media.name"
                  square
                  class="mr-1"
                />
              </div>
              <div>
                <b-img
                  v-for="color in data.item.colors"
                  :key="color.id"
                  blank
                  :alt="color.name"
                  :blank-color="color.color"
                  :style="{width: '1.5em', height: '1.5em'}"
                  :title="color.name"
                  class="mr-1"
                  rounded="circle"
                />
              </div>
            </div>
          </template>

          <!-- Column: Ref -->
          <template #cell(description)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div
                  class="align-text-top"
                  style="white-space: pre-line"
                >
                  {{ data.item.specifics_description }}
                </div>
                <div class="align-text-top">
                  {{ data.item.dimensions_description }}
                </div>
              </div>
              <feather-icon
                v-if="data.item.placement"
                v-b-tooltip.hover
                icon="TargetIcon"
                class="d-inline mr-1"
                :title="data.item.placement"
              />
              <feather-icon
                v-if="data.item.feed && data.item.feed.length != 0"
                icon="MessageCircleIcon"
                class="d-inline mr-1"
              />
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-card>
    <b-card
      v-if="feed.length > 0"
    >
      <b-card-text>
        <feed
          ref="refFeed"
          :feed="feed"
          :feed-config="feedConfig"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BImg, BTable,
} from 'bootstrap-vue'
import {
  computed,
} from '@vue/composition-api'

import useStyleMaterialsList from '../styles-edit/useStyleMaterialsList'
import Feed from '../../feed/Feed.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BImg,
    BTable,

    Feed,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
    styleData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      fetchStyleMaterials,
      refMaterialListTable,
      sortBy,
      isSortDirDesc,

      // categoryTypeFilter,
    } = useStyleMaterialsList()

    const tableColumns = [
      { key: 'category_type' },
      { key: 'description' },
      { key: 'id', sortable: true, label: 'Created at' },
    ]
    const feedConfig = {
      icons: {
      },
      headers: {
        Comment: 'title-header',
      },
      feedBody: {
        Comment: 'comments-body',
      },
    }

    const feed = computed(() => {
      const allFeeds = []
      if (refMaterialListTable.value) {
        refMaterialListTable.value.localItems.forEach(material => {
          material.feed.forEach(materialFeed => {
            allFeeds.push(materialFeed)
          })
        })
      }
      return allFeeds.sort((a, b) => a.id - b.id)
    })

    const imageMedia = allMedia => allMedia.filter(media => media.image)

    return {
      fetchStyleMaterials,

      tableColumns,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      imageMedia,
      feed,
      feedConfig,
    }
  },
}
</script>

<style>

</style>
